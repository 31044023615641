import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContainerGrid, Loader } from "../../../components";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setUserDetails } from "../../../store/slices";
import { USER_ROLES } from "../../../utils/constants";

declare global {
  interface Window {
    gigya: any;
  }
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadGigyaScript = () => {
      // Check if the script is already added
      if (!document.querySelector(`script[src*="gigya.js"]`)) {
        const script = document.createElement("script");
        const key = USER_ROLES.getGigyaByCurrentDomain();
        script.src = `https://cdns.us1.gigya.com/js/gigya.js?apikey=${key}`;
        script.onload = handleGigyaReady;
        document.body.appendChild(script);
      } else if (window.gigya) {
        handleGigyaReady();
      }
    };

    const handleGigyaReady = () => {
      window.gigya.accounts.showScreenSet({
        screenSet: "COV_CUPS-RegistrationLogin",
        startScreen: "cups-login",
        containerID: "loginScreenGigyaContainer",
        onBeforeScreenLoad: () => setIsLoading(false),
        onAfterSubmit: (event: any) => {
          if (event.response && event.response.errorCode === 0) {
            const params = {
              fields: "firstName, lastName, email",
              callback: (jwtResponse: any) => {
                processLoginSuccess(event.response, jwtResponse.id_token);
              },
            };
            window.gigya.accounts.getJWT(params);
          }
        },
      });
    };

    const processLoginSuccess = (response: any, token: string) => {
      const userData = {
        first_name: response.profile.firstName,
        last_name: response.profile.lastName,
        email: response.profile.email,
        role: USER_ROLES.EXTERNAL,
        UID: response.profile.UID,
        token,
      };
      dispatch(setUserDetails(userData));
      dispatch(setIsLoggedIn(true));
      localStorage.setItem(USER_ROLES.USER_DETAILS, JSON.stringify(userData));
      navigate("/");
    };

    loadGigyaScript();
  }, [navigate]);

  return (
    <>
      {isLoading && <Loader />}
      <Box className="loginPageContainer">
        <ContainerGrid>
          <Grid size={12}>
            <Box id="loginScreenGigyaContainer" />
          </Grid>
        </ContainerGrid>
      </Box>
    </>
  );
};

export default Login;

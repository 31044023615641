import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContainerGrid, Loader } from "../../../components";
import { ROUTE_PATHS, USER_ROLES } from "../../../utils/constants";
import { useAppDispatch } from "../../../hooks";

declare global {
  interface Window {
    gigya: any;
  }
}

const SetNewPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadGigyaScript = () => {
      // Check if the Gigya script is already added
      if (!document.querySelector(`script[src*="gigya.js"]`)) {
        const script = document.createElement("script");
        const key = USER_ROLES.getGigyaByCurrentDomain();
        script.src = `https://cdns.us1.gigya.com/js/gigya.js?apikey=${key}`;
        script.onload = handleGigyaReady;
        document.body.appendChild(script);
      } else if (window.gigya) {
        handleGigyaReady();
      }
    };

    const handleGigyaReady = () => {
      window.gigya.accounts.showScreenSet({
        screenSet: "COV_CUPS-RegistrationLogin",
        startScreen: "cups-welcome-screen",
        containerID: "setNewPasswordScreenGigyaContainer",
        onBeforeScreenLoad: () => setIsLoading(false),
        onAfterSubmit: (event: any) => {
          if (event.response && event.response.errorCode === 0) {
            navigate(`/${ROUTE_PATHS.AUTH}/${ROUTE_PATHS.LOGIN}`);
          }
        },
      });
    };

    loadGigyaScript();
  }, [navigate]);

  return (
    <>
      {isLoading && <Loader />}
      <Box className="setNewPasswordPageContainer">
        <ContainerGrid>
          <Grid size={12}>
            <Box id="setNewPasswordScreenGigyaContainer" />
          </Grid>
        </ContainerGrid>
      </Box>
    </>
  );
};

export default SetNewPassword;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../store/slices";
import { accountApi } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS, USER_ROLES } from "../utils/constants";
import { decodeJwt } from "../utils/helpers";
import { useAppSelector } from "./reduxHooks";
import { RootState } from "../store";

const useUserRole = () => {
  const { isLoggedIn } = useAppSelector((state: RootState) => state.user);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Effect runs when isLoggedIn changes, typically after login success.
  useEffect(() => {
    fetchUserData();
  }, [isLoggedIn]);

  // Initial effect that runs on component mount.
  // Used for pages where the user might be already logged in (e.g., refreshing the page).
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    const role = USER_ROLES.getRoleByCurrentUrl();
    setUserRole(role);
    if (role === USER_ROLES.INTERNAL || role === USER_ROLES.LOCALHOST) {
      fetchInternalUserData();
    } else if (role === USER_ROLES.EXTERNAL) {
      externalUserData();
    }
  };

  const fetchInternalUserData = async () => {
    try {
      const data = await accountApi.getAccountDetails();
      if (data) {
        const decodedData = decodeJwt(data.token);
        const userData = {
          first_name: decodedData.given_name,
          last_name: decodedData.family_name,
          email: decodedData.unique_name,
          role: USER_ROLES.INTERNAL,
          token: data.token,
        };
        setToken(data.token);
        updateUserData(userData);
      }
    } catch (error) {
      console.error("Failed to fetch internal user details:", error);
    }
  };

  const externalUserData = () => {
    const userData = localStorage.getItem(USER_ROLES.USER_DETAILS);
    if (!userData) {
      // Added this condition so users can access pages like reset-password without being redirected to the login page. In short, auth pages are accessible without login.
      const isAuthPages = location.pathname.includes(ROUTE_PATHS.AUTH);
      if (!isAuthPages) navigate(`/${ROUTE_PATHS.AUTH}/${ROUTE_PATHS.LOGIN}`);
    } else {
      const parsedData = JSON.parse(userData);
      setToken(parsedData.token);
      updateUserData(parsedData);
    }
  };

  const updateUserData = (userData: any) => {
    dispatch(setUserDetails(userData));
    localStorage.setItem(USER_ROLES.USER_DETAILS, JSON.stringify(userData));
  };

  return { userRole, token };
};

export default useUserRole;

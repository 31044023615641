import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContainerGrid, Loader } from "../../../components";
import { USER_ROLES } from "../../../utils/constants";

declare global {
  interface Window {
    gigya: any;
  }
}

const ExternalUser = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadGigyaScript = () => {
      // Check if the Gigya script is already added
      if (!document.querySelector(`script[src*="gigya.js"]`)) {
        const script = document.createElement("script");
        const key = USER_ROLES.getGigyaByCurrentDomain();
        script.src = `https://cdns.us1.gigya.com/js/gigya.js?apikey=${key}`;
        script.onload = handleGigyaReady;
        document.body.appendChild(script);
      } else if (window.gigya) {
        handleGigyaReady();
      }
    };

    const handleGigyaReady = () => {
      window.gigya.accounts.showScreenSet({
        screenSet: "COV_CUPS-ProfileUpdate",
        startScreen: "cups-update-profile-screen",
        containerID: "profileScreenGigyaContainer",
        onBeforeScreenLoad: () => {
          setIsLoading(false);
        },
      });
    };

    loadGigyaScript();
  }, [navigate]);

  return (
    <>
      {isLoading && <Loader />}
      <Box className="myAccountPageContainer external">
        <ContainerGrid>
          <Grid size={12}>
            <Box id="profileScreenGigyaContainer" />
          </Grid>
        </ContainerGrid>
      </Box>
    </>
  );
};

export default ExternalUser;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { Layout, Loader } from "./components";
import { useAppSelector, useAxiosLoader, useUserRole } from "./hooks";
import AppRoutes from "./routes";
import { ROUTE_PATHS, USER_ROLES } from "./utils/constants";
import "./App.scss";
import { RootState } from "./store";

const App = () => {
  const [axiosLoading] = useAxiosLoader();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const { userRole, token } = useUserRole();
  const { userDetails } = useAppSelector((state: RootState) => state.user);
  const location = useLocation();

  useEffect(() => {
    const loadGigyaScript = () => {
      if (!window.gigya && userDetails.role === USER_ROLES.EXTERNAL) {
        const script = document.createElement("script");
        const key = USER_ROLES.getGigyaByCurrentDomain();
        script.src = `https://cdns.us1.gigya.com/js/gigya.js?apikey=${key}`;
        script.onload = () => {
          console.log("Gigya script loaded successfully");
        };
        script.onerror = () => {
          console.error("Error loading the Gigya script");
        };
        document.body.appendChild(script);
      }
    };
    loadGigyaScript();
  }, [userDetails]);

  useEffect(() => {
    const timer = setTimeout(() => setIsAppLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  const shouldShowLoader = isAppLoading || axiosLoading || !token;

  const isAuthPath = [ROUTE_PATHS.LOGIN, ROUTE_PATHS.RESET_PASSWORD, ROUTE_PATHS.SET_NEW_PASSWORD].some((path) =>
    location.pathname.includes(path)
  );

  return (
    <Box className="appContainer">
      <Layout>
        {shouldShowLoader && (!isAuthPath || isAppLoading) && <Loader />}
        {!isAppLoading && (isAuthPath ? <AppRoutes /> : token && <AppRoutes />)}
      </Layout>
    </Box>
  );
};

export default App;

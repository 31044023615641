import { IconButton, Menu, MenuItem, Avatar, Box, Button } from "@mui/material";
import { MouseEvent } from "react";
import { USER_ROLES } from "../../../utils/constants";
import { getInitials } from "../../../utils/helpers";
import UserRoleToggleSwitch from "../../UserRoleToggleSwitch/UserRoleToggleSwitch";

interface UserMenuProps {
  userDetails: { first_name: string; last_name: string };
  openUserMenu: (event: MouseEvent<HTMLElement>) => void;
  closeUserMenu: () => void;
  handleLogout: () => void;
  userMenuAnchorEl: HTMLElement | null;
}

const UserMenu = ({ userDetails, openUserMenu, closeUserMenu, handleLogout, userMenuAnchorEl }: UserMenuProps) => {
  const role = USER_ROLES.getRoleByCurrentUrl();
  const isMenuOpen = Boolean(userMenuAnchorEl);
  const isLocalhostRole = role === USER_ROLES.LOCALHOST;
  const isExternalRole = role === USER_ROLES.EXTERNAL;

  return (
    <Box sx={{ flexGrow: 0, display: { xs: "none", sm: "none", md: "block" } }}>
      <Box display="flex">
        {/* {isLocalhostRole && <UserRoleToggleSwitch />} */}
        <IconButton onClick={openUserMenu} sx={{ p: 0 }}>
          <Avatar>{getInitials(userDetails.first_name, userDetails.last_name)}</Avatar>
        </IconButton>

        {isExternalRole && (
          <Menu
            sx={{ mt: "45px", "& .MuiMenu-paper": { backgroundColor: "#262626" } }}
            anchorEl={userMenuAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={closeUserMenu}>
            <MenuItem onClick={handleLogout} sx={{ padding: 0 }}>
              <Button sx={{ display: "block", textTransform: "none", color: "#ffffff" }}>Logout</Button>
            </MenuItem>
          </Menu>
        )}
      </Box>
    </Box>
  );
};

export default UserMenu;

import Grid from "@mui/material/Grid2";
import { useState, useEffect, useRef } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { LABELS, MESSAGES as msg, ROUTE_PATHS } from "../../../utils/constants";
import { DataTable, PageTitle, ContainerGrid, AddOrUpdateSavedFiltersModal, Loader } from "../../../components";
import { FieldStatusData, RequestData, SavedFilterData } from "../../../api/type";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { requestApi, savedFilterApi, fieldStatusApi } from "../../../api";
import {
  DATA_TABLE_ACTION_CLONE,
  DATA_TABLE_ACTION_EDIT,
  DATA_TABLE_ACTION_ROW_CLICK,
  DATA_TABLE_ACTION_VIEW,
  setFilters,
  triggerRowAction,
  updateColumnVisibilityState,
  MODAL_ACTION_ADD,
  showModal,
  showAlert,
} from "../../../store/slices";
import {
  compareFieldVisibilityStates,
  filterFalseValues,
  formatDateColumnsInFilters,
  hasFiltersChanged,
} from "../../../utils/helpers";
import { NON_OEM_LAYOUT_COLUMNS, OEM_LAYOUT_COLUMNS, initialSortingFilter } from "../contant";
import { RootState } from "../../../store";

const InternalUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isInitialRender = useRef(true);

  const { filters, columnVisibility, isResetting, rowAction } = useAppSelector((state: RootState) => state.dataTable);
  const { userDetails } = useAppSelector((state: RootState) => state.user);

  const [dataTable, setDataTable] = useState<RequestData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [isOemLayoutEnabled, setIsOemLayoutEnabled] = useState<boolean>(true);
  const [columns, setColumns] = useState<MRT_ColumnDef<RequestData>[]>(OEM_LAYOUT_COLUMNS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasInitialFiltersBeenApplied, setHasInitialFiltersBeenApplied] = useState<Boolean>(false);
  const [savedFiltersDropdown, setSavedFiltersDropdown] = useState<SavedFilterData[]>([]);
  const [isSavedFiltersDropdownLoading, setIsSavedFiltersDropdownLoading] = useState<boolean>(false);
  const [hasFetchedSavedFilter, setHasFetchedSavedFilter] = useState<boolean>(false);
  const [activeSavedFilter, setActiveSavedFilter] = useState<string>("");
  const [isShowSavedFiltersModal, setIsShowSavedFiltersModal] = useState<boolean>(false);
  const [fieldStatus, setFieldStatus] = useState<FieldStatusData>();
  const [isFieldStatusLoaded, setIsFieldStatusLoaded] = useState<boolean>(false);

  const navigateToRequestDetail = (id: string, actionType: string) => {
    navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}/${id}?type=${actionType}`);
  };

  const applySelectedSavedFilter = (event: SelectChangeEvent) => {
    const selectedFilterId = event.target.value;
    setActiveSavedFilter(selectedFilterId);
    const selectedFilter = savedFiltersDropdown.find((filter) => filter.id === Number(selectedFilterId));
    if (selectedFilter) {
      const filterColumns = formatDateColumnsInFilters(selectedFilter.filter.savedColumns);
      dispatch(setFilters({ column: filterColumns, pagination: selectedFilter.filter.savedPagination }));
    } else {
      dispatch(
        setFilters({
          column: [],
          sorting: initialSortingFilter,
          pagination: {
            pageIndex: 0,
            pageSize: 10,
          },
        })
      );
    }
  };

  const loadSavedFiltersOnDropdownOpen = () => {
    if (!hasFetchedSavedFilter) {
      setIsSavedFiltersDropdownLoading(true);
      fetchSavedFilters();
    }
  };

  const resetState = () => {
    setIsLoading(true);
    setSavedFiltersDropdown([]);
    setActiveSavedFilter("");
    setHasFetchedSavedFilter(false);
    fetchRequestData();
    dispatch(setFilters({ sorting: initialSortingFilter }));
  };

  const saveFieldStatusData = async (fields: Record<string, boolean>) => {
    const payload = { field: fields, user_email: userDetails.email };
    try {
      if (fieldStatus?.id) await fieldStatusApi.updateFieldStatus({ ...payload, id: fieldStatus?.id });
      else await fieldStatusApi.createFieldStatus(payload);
      fetchSavedFields(false);
    } catch (error) {
      console.error("Failed to save field status:", error);
    }
  };

  const saveFilterData = async (formData: { id?: number; filter_name?: string }) => {
    // Check if there are any filters to save
    if (!hasFiltersChanged(filters.column)) {
      dispatch(showAlert({ message: msg.NO_FILTER_TO_SAVE, alertType: "warning" }));
      return;
    }

    const savedColumns = filters.column;
    const userEmail = userDetails.email;

    const payload = {
      filter: {
        oem_layout: isOemLayoutEnabled,
        savedColumns: savedColumns,
        savedPagination: {
          pageIndex: filters.pagination.pageIndex,
          pageSize: filters.pagination.pageSize,
        },
      },
      user_email: userEmail,
    };

    try {
      if (formData.id) {
        await savedFilterApi.updateSavedFilter({ ...payload, id: formData.id });
      } else if (formData.filter_name) {
        await savedFilterApi.createSavedFilter({
          ...payload,
          filter_name: formData.filter_name,
        });
      }
      setHasFetchedSavedFilter(false);
    } catch (err) {
      console.error("Failed to save filters:", err);
    }
  };

  const fetchSavedFields = async (shouldDispatch = true) => {
    try {
      const response = await fieldStatusApi.getFieldStatus(userDetails.email);
      const savedData = response.data.data;
      if (savedData.length) {
        const savedFieldStatus = savedData[0];
        setFieldStatus(savedFieldStatus);
        shouldDispatch && dispatch(updateColumnVisibilityState(savedFieldStatus.field));
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsFieldStatusLoaded(true);
    }
  };

  const fetchSavedFilters = async () => {
    try {
      const { data } = await savedFilterApi.getSavedFilter(userDetails.email);
      // Filter the saved filters based on OEM layout
      const filteredSavedFilters = filterByOemLayout(data.data, isOemLayoutEnabled);
      setSavedFiltersDropdown(filteredSavedFilters);
      setHasFetchedSavedFilter(true);
      setIsSavedFiltersDropdownLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchRequestData = async () => {
    try {
      const updatedFilters = {
        ...filters,
        column: [...filters.column, { id: "is_ep", value: isOemLayoutEnabled }],
      };
      const columnsToSet = isOemLayoutEnabled ? OEM_LAYOUT_COLUMNS : NON_OEM_LAYOUT_COLUMNS;
      setColumns(columnsToSet);
      const { data } = await requestApi.getRequest(updatedFilters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterByOemLayout = (filters: SavedFilterData[], isEnabled: boolean) => {
    return filters.filter((filter) => filter.filter.oem_layout === isEnabled);
  };

  useEffect(() => {
    dispatch(setFilters({ sorting: initialSortingFilter }));
    setHasInitialFiltersBeenApplied(true);
  }, [dispatch]);

  useEffect(() => {
    fetchSavedFields();
  }, []);

  useEffect(() => {
    if (hasInitialFiltersBeenApplied && !isResetting) {
      fetchRequestData();
    }
  }, [filters, hasInitialFiltersBeenApplied]);

  useEffect(() => {
    if (!isInitialRender.current) {
      resetState();
    }
    isInitialRender.current = false;
  }, [isOemLayoutEnabled]);

  useEffect(() => {
    if (!isFieldStatusLoaded || !columnVisibility || Object.keys(columnVisibility).length === 0) {
      return;
    }
    const hasExistingFieldStatus = fieldStatus && Object.keys(fieldStatus.field).length > 0;
    if (hasExistingFieldStatus) {
      const { isEqual, differingFieldVisibility } = compareFieldVisibilityStates(columnVisibility, fieldStatus.field);
      if (!isEqual) {
        saveFieldStatusData(differingFieldVisibility);
      }
    } else {
      const filteredVisibility = filterFalseValues(columnVisibility);
      saveFieldStatusData(filteredVisibility);
    }
  }, [columnVisibility, isFieldStatusLoaded]);

  useEffect(() => {
    if (rowAction) {
      const { type, data } = rowAction;
      switch (type) {
        case DATA_TABLE_ACTION_CLONE:
          navigateToRequestDetail(data.id, LABELS.CLONE);
          break;
        case DATA_TABLE_ACTION_EDIT:
          navigateToRequestDetail(data.id, LABELS.EDIT);
          break;
        case DATA_TABLE_ACTION_VIEW:
          break;
        case DATA_TABLE_ACTION_ROW_CLICK:
          navigateToRequestDetail(data.id, LABELS.EDIT);
          break;
        default:
          console.warn("Unknown action type:", type);
      }
      // Reset action after handling
      dispatch(triggerRowAction(null));
    }
  }, [rowAction, dispatch]);

  return (
    <Box>
      <PageTitle
        title="Request"
        handleBtnNew={() => navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}?type=${LABELS.CREATE}`)}
      />
      <ContainerGrid sx={{ justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }}>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <FormControl fullWidth>
              <InputLabel>Select OEM Layout</InputLabel>
              <Select
                value={isOemLayoutEnabled}
                label="Select OEM Layout"
                onChange={(e) => setIsOemLayoutEnabled(e.target.value === "true")}>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                sx={{ mr: 2, padding: "15px" }}
                onClick={() => {
                  setIsShowSavedFiltersModal(true);
                  dispatch(showModal({ type: MODAL_ACTION_ADD }));
                }}>
                Save Filters
              </Button>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: 160 }}>
                <InputLabel>Apply Saved Filter</InputLabel>
                <Select
                  value={activeSavedFilter}
                  label="Apply Saved Filter"
                  onChange={applySelectedSavedFilter}
                  onOpen={loadSavedFiltersOnDropdownOpen}>
                  {isSavedFiltersDropdownLoading ? (
                    <MenuItem disabled>
                      <em>Loading...</em>
                    </MenuItem>
                  ) : (
                    [
                      <MenuItem key="none" value="">
                        <em>None</em>
                      </MenuItem>,
                      ...savedFiltersDropdown.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.filter_name}
                        </MenuItem>
                      )),
                    ]
                  )}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </ContainerGrid>
      {!isLoading && (
        <ContainerGrid>
          <Grid size={12}>
            <DataTable
              data={dataTable}
              totalRows={totalRows}
              columns={columns}
              exportFileName="Request"
              actionButton={{ clone: true, edit: true }}
            />
          </Grid>
        </ContainerGrid>
      )}
      {isLoading && <Loader />}
      {isShowSavedFiltersModal && <AddOrUpdateSavedFiltersModal handleSubmit={saveFilterData} />}
    </Box>
  );
};

export default InternalUser;
